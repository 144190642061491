import React, { Component } from "react"
import "styles/pages/projects/edreams.scss"

// Components
import { Link } from "gatsby"
import Layout from "components/Layout"
import Carousel from "react-bootstrap/Carousel"
import { BsChevronLeft } from "react-icons/bs";

import frame from "images/edreams/mocks/frame.png"
import mock_1 from "images/edreams/mocks/mock_1.png"
import mock_2 from "images/edreams/mocks/mock_2.png"
import mock_3 from "images/edreams/mocks/mock_3.png"
import mock_4 from "images/edreams/mocks/mock_4.png"
import mock_5 from "images/edreams/mocks/mock_5.png"
import mock_6 from "images/edreams/mocks/mock_6.png"
import mock_7 from "images/edreams/mocks/mock_7.png"
import mock_8 from "images/edreams/mocks/mock_8.png"
import mock_9 from "images/edreams/mocks/mock_9.png"


class EdreamsPage extends Component {

  // MARK: - Properties

  appMocks = [
    mock_1,
    mock_2,
    mock_3,
    mock_4,
    mock_5,
    mock_6,
    mock_7,
    mock_8,
    mock_9
  ]

  render() {
    return (
      <Layout>
        <div className="project-page">
          <Link to="/" className="back-button container">
            <BsChevronLeft className="chevron-left-icon" size="24px" />
            <div className="title">Back to projects</div>
          </Link>
          <h1 className="title container">eDreams</h1>
          <div className="download-appstore container"><a href="https://apps.apple.com/app/apple-store/id953286746" target="_blank" rel="noopener noreferrer">View on the App Store</a></div>
          <div className="introduction container">
            <p>The city has hired 3,000 disease detectives and case monitors, who are supposed to identify anyone who has come into contact with the hundreds of people who are still testing positive for the virus in the city every day. But the first statistics from the program, which began on June 1, indicate that tracers are often unable to locate infected people or gather information from them.</p>
          </div>
          <div className="app">
            <div className="fadein">
              <img className="frame" src={frame} alt="eDreams app." />
              <Carousel className="carousel"
                fade={true}
                controls={false}
                indicators={false}
                interval={1600}
                pause={false}
                keyboard={false}
                touch={false}>
                {
                  this.appMocks.map((mock, index) => {
                    return (
                      <Carousel.Item className="carousel-item">
                        <img src={mock} alt="eDreams app." />
                      </Carousel.Item>
                    )
                  })
                }
              </Carousel>
            </div>
          </div>
          <div className="info-branded">
            <div className="section container">
              <h2 className="header">Behind the scenes</h2>
              <div className="content">
                <p>
                  Doesn’t that make a portfolio sound incredibly important? Well, it is! It all starts with
                  understanding the three main parts that make up a portfolio: the story, plan, and the tool.
                  Most of you might think a portfolio is simply a place to show off your work. You’re not wrong,
                  but it’s so much more than that. It’s a story about who you are as an individual.
                </p>
                <p>
                  Whether you’re a designer, artist, photographer, or another creative professional—each of us brings
                  our own unique perspective and individual voice to our work. And your voice helps shape your story;
                  your interests, your passions, your skills, how you think, how you work with others, what you can do,
                  and what you want to do. And because your story is a never-ending one that you’ll always be adding to
                  and evolving over time, your portfolio also becomes a plan. It’s a plan about where you want to go next,
                  the type of work you envision creating, and what you’re capable of in the future.
                </p>
              </div>
            </div>
            <div className="section container">
              <h2 className="header">The team</h2>
              <div className="content">
                <p>
                  Some of us like to read, some of us like to listen to audio books, and some prefer to sit back and watch a
                  video. Well, you can do it all with That Book. That Portfolio Book is intended to be both book and course combined.
                  In That Portfolio Book, you’ll not only get commentary in the video (which may stray off script every now and then),
                  but will also get exclusive interviews, and even instructional videos on design and portfolio thinking.
                </p>
                <p>
                  Whether you’re a designer, artist, photographer, or another creative professional—each of us brings
                  our own unique perspective and individual voice to our work. And your voice helps shape your story;
                  your interests, your passions, your skills, how you think, how you work with others, what you can do,
                  and what you want to do. And because your story is a never-ending one that you’ll always be adding to
                  and evolving over time, your portfolio also becomes a plan. It’s a plan about where you want to go next,
                  the type of work you envision creating, and what you’re capable of in the future.
                </p>
              </div>
            </div>
          </div>
          <div className="info">
            <div className="section container">
              <h2 className="header">Behind the scenes</h2>
              <div className="content">
                <p>
                  Doesn’t that make a portfolio sound incredibly important? Well, it is! It all starts with
                  understanding the three main parts that make up a portfolio: the story, plan, and the tool.
                  Most of you might think a portfolio is simply a place to show off your work. You’re not wrong,
                  but it’s so much more than that. It’s a story about who you are as an individual.
                </p>
                <p>
                  Whether you’re a designer, artist, photographer, or another creative professional—each of us brings
                  our own unique perspective and individual voice to our work. And your voice helps shape your story;
                  your interests, your passions, your skills, how you think, how you work with others, what you can do,
                  and what you want to do. And because your story is a never-ending one that you’ll always be adding to
                  and evolving over time, your portfolio also becomes a plan. It’s a plan about where you want to go next,
                  the type of work you envision creating, and what you’re capable of in the future.
                </p>
              </div>
            </div>
            <div className="section container">
              <h2 className="header">The team</h2>
              <div className="content">
                <p>
                  Some of us like to read, some of us like to listen to audio books, and some prefer to sit back and watch a
                  video. Well, you can do it all with That Book. That Portfolio Book is intended to be both book and course combined.
                  In That Portfolio Book, you’ll not only get commentary in the video (which may stray off script every now and then),
                  but will also get exclusive interviews, and even instructional videos on design and portfolio thinking.
                </p>
                <p>
                  Whether you’re a designer, artist, photographer, or another creative professional—each of us brings
                  our own unique perspective and individual voice to our work. And your voice helps shape your story;
                  your interests, your passions, your skills, how you think, how you work with others, what you can do,
                  and what you want to do. And because your story is a never-ending one that you’ll always be adding to
                  and evolving over time, your portfolio also becomes a plan. It’s a plan about where you want to go next,
                  the type of work you envision creating, and what you’re capable of in the future.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default EdreamsPage